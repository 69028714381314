(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name trek.filter:uriencode
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('trek')
    .filter('uriencode', uriencode);

  function uriencode() {
      return window.encodeURIComponent;
  }
}());
